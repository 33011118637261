import { useState } from "react";
import {
  uploadFiles as uploadFilesStorage,
  deleteFile,
} from "src/tools/Storage/firebaseStorageSDK";

function useStorage() {
  // const [url, setUrl] = useState(null);
  const [progress, setProgress] = useState(0);

  const uploadFiles = async (acceptedFiles) => {
    return await uploadFilesStorage(acceptedFiles, setProgress);
  };

  const returnObject = {
    uploadFiles,
    deleteFile,
    progress,
  };

  return returnObject;
}

export default useStorage;
