import { useState } from "react";
import ReactQuill from "react-quill";
import classNames from "classnames";
import "react-quill/dist/quill.snow.css";
import {
  ReactQuillCustom,
  modules,
} from "./components/ReactQuillCustom/ReactQuillCustom";

function TextEditor(props) {
  const [bodyInput, setBodyInput] = useState(props.initialValue || "");
  if (props.refStates) {
    props.refStates[props.id] = bodyInput;
  }
  return (
    <>
      <ReactQuillCustom />
      <ReactQuill
        className={classNames("TextEditor", props.className)}
        id={props.id}
        theme="snow"
        modules={modules}
        value={bodyInput}
        onChange={setBodyInput}
        placeholder={props.placeholder}
        ref={(el) => {
          // an example with the use of 'ref':
          // https://github.com/zenoamaro/react-quill#methods
          if (el) {
            // @ts-ignore
            const theme = el.editor.theme;
            const tooltip = theme.tooltip.root;
            const picker = theme.pickers[0];
            const colorPicker = theme.pickers[1];
            tooltip
              .querySelector("a.ql-action")
              .setAttribute("href", "about:blank");
            tooltip
              .querySelector("a.ql-remove")
              .setAttribute("href", "about:blank");
            picker.label.setAttribute("aria-label", "Heading");
            colorPicker.label.setAttribute("aria-label", "Font Color");
          }
        }}
      />
    </>
  );
}

export default TextEditor;
