export const getPublicUrl = (authUrl) => {
  //https://stackoverflow.com/questions/43851742/firebase-storage-very-slow-compared-to-firebase-hosting
  // https://cloud.google.com/storage/docs/access-public-data#api-link
  const encodedFileName = authUrl.substring(
    authUrl.lastIndexOf("/") + 1,
    authUrl.lastIndexOf("?")
  );
  const filename = decodeURIComponent(encodedFileName);
  const bucketName = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
  const publicUrl = `https://storage.googleapis.com/${bucketName}/${filename}`;
  return publicUrl;
};
