// https://stackoverflow.com/a/76956341
// https://stackoverflow.com/questions/70035179/readasdataurl-async-await
// https://stackoverflow.com/questions/64480329/how-can-i-convert-blob-object-to-a-file-and-send-it-to-the-server
// https://stackoverflow.com/a/75051596
// https://www.youtube.com/watch?v=tF6L6IIo-yg
// https://stackoverflow.com/questions/19032406/convert-html5-canvas-into-file-to-be-uploaded
// https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f

function removeExtension(filename) {
  return filename.substring(0, filename.lastIndexOf(".")) || filename;
}

// it doesn't support Safari
// read "type parameter supports "image/webp""
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#browser_compatibility
//
export function convertImgFormat(file, newFormat, width, height) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result.toString();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        // canvas.width = img.width;
        // canvas.height = img.height;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const fileName = removeExtension(file.name);
        canvas.toBlob((blob) => {
          const blobFile = new File([blob], `${fileName}.${newFormat}`, {
            type: `image/${newFormat}`,
          });
          resolve(blobFile);
        }, `image/${newFormat}`);
      };
    };
    reader.readAsDataURL(file);
  });
}
