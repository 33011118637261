import { useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { serverTimestamp } from "firebase/firestore";
import { useStorage } from "src/hooks";
import { addOrUpdateDoc } from "src/tools/Database/firebaseDBRestAPI";
import { useAuth } from "src/contexts/AuthContext";
import { usePostsContextUpdater } from "src/pages/Posts/context/PostsProvider/PostsProvider";
import { HttpClient } from "src/tools/HttpClient/BuildInFetch";
import { convertImgFormat } from "src/tools/convertImgFormat";
// import { user } from "src/contexts/AuthContext/tools/User";

function useEditablePostForm(post) {
  const setPosts = usePostsContextUpdater();
  const refInputFields = useRef({
    author: "",
    body: "",
    category: "",
    title: "",
    url: [""],
  }).current;
  const { currentUser } = useAuth();
  const { uploadFiles, deleteFile, progress } = useStorage();
  const params = useParams();
  const navigate = useNavigate();
  const [url, setUrl] = useState(post?.url || [""]);

  const handleSubmit = async (e) => {
    const httpClient = new HttpClient();
    // user.signInCachedUser();
    e.preventDefault();
    const docRef = await addOrUpdateDoc(
      "posts",
      params.postid,
      {
        ...refInputFields,
        url,
        date: new Date(),
      },
      { httpClient: httpClient, user: currentUser }
    );
    if (params.postid) {
      setPosts((prev) => {
        const index = prev.findIndex((item) => item.id === params.postid);
        prev[index] = docRef;
        return [...prev];
      });
    } else {
      setPosts((prev) => {
        prev.unshift(docRef);
        return [...prev];
      });
    }
    console.log(docRef);
    if (typeof docRef !== "undefined") {
      if (params.postid) {
        navigate(`../../../${docRef.id}`);
      } else {
        navigate(`../${docRef.id}`);
      }
    }
  };

  const handleDropFile = async (acceptedFiles) => {
    try {
      const result = await convertImgFormat(acceptedFiles[0], "webp", 320, 200);
      const resolvedUrl = await uploadFiles([result, acceptedFiles[0]]);
      setUrl(resolvedUrl);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveFile = async (e) => {
    e.preventDefault();
    try {
      await deleteFile(url[0]);
      await deleteFile(url[1]);
      setUrl([""]);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    refInputFields,
    url,
    setUrl,
    handleSubmit,
    handleDropFile,
    handleRemoveFile,
    progress,
  };
}

export default useEditablePostForm;
