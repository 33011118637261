// inspired from
// https://www.youtube.com/watch?v=Aoz0eQAbEUo
// https://codesandbox.io/s/dropzone-d74g4

import { Fragment } from "react";
import { useDropzone as useReactDropzone } from "react-dropzone";
import classNames from "classnames";
// import uploadImg from "src/assets/cloud-upload-regular-240.png";
import { ReactComponent as UploadImg } from "src/assets/icons/cloud-upload.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-svgrepo-com.svg";
import { truncateText } from "src/tools/truncateText";
import { Button } from "src/elements/Forms";
import "./DropZone.css";

function DropZone(props) {
  const handleClickDelete = props.onRemoveFile;
  const handleDrop = props.onDropFile;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useReactDropzone({
    accept: { "image/*": [] },
    noClick: !!props.url,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
    onDrop: handleDrop,
  });
  const btnClass = classNames("DropZone", props.className, {
    "DropZone--isDragActive": isDragActive,
    "DropZone--isDragZoneFilled": !!props.url,
  });

  return (
    <div {...getRootProps({ className: btnClass })}>
      {/* <progress value={progress} max="100" /> */}
      <input {...getInputProps()} />
      {!props.url ? (
        // dropzone empty
        <Fragment>
          {/* <img className="DropZone__img" src={uploadImg} alt="" /> */}
          <UploadImg className="DropZone__img" />
          {isDragAccept && <p>All files will be accepted</p>}
          {isDragReject && <p>Some files will be rejected</p>}
          {!isDragActive && <p>Drag 'n' drop your file here</p>}
        </Fragment>
      ) : (
        // dropzone filled
        <Fragment>
          <img className="DropZone__imgUploaded" src={props.url} alt="" />
          <span className="DropZone__filename">{truncateText(props.url)}</span>
          <Button buttonType="icon" onClick={handleClickDelete}>
            <CloseIcon />
          </Button>
        </Fragment>
      )}
    </div>
  );
}

export default DropZone;
