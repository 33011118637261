import classNames from "classnames";
import { Button } from "src/elements/Forms";
import { InputField, DropZone, TextEditor } from "src/components";
import useEditablePostForm from "./useEditablePostForm";
import "./EditablePostForm.scss";

function EditablePostForm(props) {
  const {
    refInputFields,
    url,
    handleSubmit,
    handleRemoveFile,
    handleDropFile,
    progress,
  } = useEditablePostForm(props.post);

  if (!refInputFields && props.post) return <div>Loading...</div>;

  return (
    <form className={classNames("EditablePostForm", props.className)}>
      <div className="EditablePostForm__upper">
        <div className="EditablePostForm__fields">
          <InputField
            placeholder="Author"
            id="author"
            initialValue={props.post?.author}
            refStates={refInputFields}
          />
          <InputField
            placeholder="Category"
            id="category"
            initialValue={props.post?.category}
            refStates={refInputFields}
          />
          <InputField
            placeholder="Title"
            id="title"
            initialValue={props.post?.title}
            refStates={refInputFields}
          />
        </div>
        <DropZone
          className="EditablePostForm__dropZone"
          onDropFile={handleDropFile}
          onRemoveFile={handleRemoveFile}
          url={url[0]}
          progress={progress}
        />
      </div>
      <div className="EditablePostForm__bottom">
        <div className="EditablePostForm__bodyInput">
          <TextEditor
            className="EditablePostForm__bodyQuill"
            id="body"
            placeholder={`Write your new blog`}
            initialValue={props.post?.body}
            refStates={refInputFields}
          />
        </div>
        <Button
          className="EditablePostForm__button"
          buttonType="text"
          onClick={handleSubmit}
          type="submit"
        >
          Submit
        </Button>
      </div>
    </form>
  );
}

export default EditablePostForm;

// Add tooltip attributes from toolbar options
// https://github.com/quilljs/quill/issues/1084
